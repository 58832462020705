<template>
  <div class="bg-gray-25 h-full">
    <div class="grid grid-cols-1">
      <div class="w-full max-w-xl mx-auto py-20 px-6 xl:px-0">
        <transition
          enter-active-class="transition ease-out duration-300"
          enter-class="opacity-0"
          enter-to-class="opacity-100"
          leave-active-class="transition ease-in duration-100"
          leave-class="opacity-100"
          leave-to-class="opacity-0"
          mode="out-in"
        >
          <router-view></router-view>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';

export default {
  name: "AnswerMiniview",

  async mounted() {
    this.setAnswerName('');
    await this.getMiniview(this.$route.params.uuid);
  },

  beforeRouteLeave (to, from , next) {
    // If the user did not confirm leave, prevent losing unsaved changes by canceling navigation
    if (!this.confirmLeave()){
      next(false)
    } else {
      // Navigate to next view
      next()
    }
  },

  created() {
    window.addEventListener('beforeunload', this.beforeWindowUnload)
  },

  beforeDestroy() {
    window.removeEventListener('beforeunload', this.beforeWindowUnload)
    this.setAnswerName('');
  },

  methods: {
    ...mapActions({
      getMiniview: 'miniviews/get'
    }),

    ...mapMutations({
      setAnswerName: "miniviews/setAnswerName",
    }),

    confirmLeave() {
      return window.confirm('Do you really want to leave? If you close this window, you will lose all your answers. If you can’t finish answering all the questions now, please keep this window open.')
    },

    beforeWindowUnload(e) {
      if (!this.confirmLeave()) {
        // Cancel the event
        e.preventDefault()
        // Chrome requires returnValue to be set
        e.returnValue = ''
      }   
    },
  },
};
</script>
